import React from 'react';
import PropTypes from 'prop-types';

export const IconCheckCircle = ({ className }) => (
  <svg className={className} fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 0.669946C8.25413 1.10537 8.88147 1.73021 9.31991 2.4826C9.75835 3.23498 9.99268 4.08883 9.99971 4.95962C10.0067 5.8304 9.78624 6.68792 9.36001 7.44729C8.93378 8.20667 8.31662 8.84155 7.56962 9.28911C6.82262 9.73666 5.97168 9.98136 5.10105 9.99898C4.23042 10.0166 3.37028 9.80653 2.60578 9.38957C1.84128 8.97261 1.19894 8.36321 0.742333 7.6217C0.285731 6.8802 0.0307047 6.0323 0.00250006 5.16195L0 4.99995L0.00250006 4.83795C0.0305016 3.97444 0.281774 3.13293 0.73182 2.39544C1.18187 1.65796 1.81533 1.04967 2.57045 0.629887C3.32557 0.210103 4.17657 -0.00685539 5.0405 0.000165123C5.90443 0.00718564 6.7518 0.237945 7.5 0.669946ZM6.8535 3.64645C6.7674 3.56036 6.65285 3.50864 6.53134 3.501C6.40983 3.49336 6.2897 3.53032 6.1935 3.60495L6.1465 3.64645L4.5 5.29245L3.8535 4.64645L3.8065 4.60495C3.71029 4.53037 3.59019 4.49346 3.4687 4.50112C3.34722 4.50879 3.2327 4.5605 3.14663 4.64658C3.06056 4.73265 3.00884 4.84717 3.00118 4.96865C2.99351 5.09013 3.03043 5.21024 3.105 5.30645L3.1465 5.35345L4.1465 6.35345L4.1935 6.39495C4.28119 6.46298 4.38902 6.4999 4.5 6.4999C4.61098 6.4999 4.71881 6.46298 4.8065 6.39495L4.8535 6.35345L6.8535 4.35345L6.895 4.30645C6.96963 4.21024 7.00658 4.09012 6.99894 3.96861C6.9913 3.84709 6.93959 3.73254 6.8535 3.64645Z" fill="#44AB4A"/>
  </svg>
);

IconCheckCircle.defaultProps = {
  className: ''
};

IconCheckCircle.propTypes = {
  className: PropTypes.string
};
