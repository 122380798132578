import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';
import PasswordStrength from '../../elements/PasswordStrength';
import { passwordStrength } from '../../../utils/validation';

export default function Password(props) {
  const {
    className,
    hideLabel,
    input,
    inputProps,
    meta: { touched, error },
    withStrength,
    maxLength,
    errorIcon,
    setStrength
  } = props;

  const [strengthScore, setStrengthScore] = useState(0);
  const label = input.value && !hideLabel;
  const notEmpty = label ? styles['not-empty'] : '';
  const isError = touched && error ? styles.isError : '';
  const classes = [styles.root, className, notEmpty, isError]
    .filter(Boolean)
    .join(' ');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (withStrength) {
      const score = passwordStrength(input.value);
      setStrengthScore(score);
      setStrength && setStrength(score);
    }
  }, [input.value]);

  return (
    <div className={styles.containerField}>
      <label className={styles.label}>{inputProps.label}</label>
      <div className={classes}>
        {inputProps.icon &&
        <span>
          <img
            alt="icon-text-field"
            onError={({ currentTarget }) => {
              currentTarget.remove();
            }}
            src={`assets/${inputProps.icon}`}
          />
        </span>
        }
        <input {...input} {...inputProps}
          className={styles['input-field']}
          maxLength={maxLength} type={show ? 'text' : 'password'}/>
        <button onClick={() => setShow(!show)} type="button">
          <img
            alt={show ? 'show' : 'hide'}
            src={`/assets/ic-password-${show ? 'show' : 'hide'}.svg`}
          />
        </button>
      </div>
      {touched && error && <span className={styles.error}>
        {errorIcon && <>{errorIcon}&nbsp;</>}{error}
      </span>}
      {withStrength && input.value &&
        <PasswordStrength score={strengthScore} value={input.value} />
      }
      {inputProps.info && <span className={styles.info}>{inputProps.info}</span>}
    </div>
  );
}

Password.defaultProps = {
  className: '',
  errorIcon: null,
  hideLabel: false,
  input: {},
  inputProps: {},
  maxLength: 16,
  meta: {},
  setStrength: () => {},
  withStrength: false,
};

Password.propTypes = {
  className: PropTypes.string,
  errorIcon: PropTypes.node,
  hideLabel: PropTypes.bool,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  maxLength: PropTypes.number,
  meta: PropTypes.object,
  setStrength: PropTypes.func,
  withStrength: PropTypes.bool,
};
