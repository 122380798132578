export const EXPIRE_TIME_STORAGE = 'mrchnt_ptm_dash_admin_expire_time';
export const TOKEN_STORAGE = 'mrchnt_ptm_dash_admin_access_token';
export const USER_DATA_STORAGE = 'mrchnt_ptm_dash_admin_user_data';
export const USERID_MYPTM_ACTIVATION = 'mrchnt_ptm_dash_admin_userid_myptm_activation';
export const USER_RESET_PASSWORD = 'mrchnt_ptm_dash_admin_user_reset_password';
export const USERS_RESET_PASSWORD = 'mrchnt_ptm_dash_admin_users_reset_password';
export const USERS_TEMP_TOKEN = 'mrchnt_ptm_dash_admin_users_temp_token';

export function setToken(value) {
  localStorage.setItem(TOKEN_STORAGE, value);
}

export function getToken() {
  return localStorage.getItem(TOKEN_STORAGE);
}

export function setTempToken(value) {
  localStorage.setItem(USERS_TEMP_TOKEN, value);
}

export function getTempToken() {
  return localStorage.getItem(USERS_TEMP_TOKEN);
}

export function clearStorages() {
  localStorage.removeItem(TOKEN_STORAGE);
  localStorage.removeItem(EXPIRE_TIME_STORAGE);
  localStorage.removeItem(USER_DATA_STORAGE);
  localStorage.removeItem(USERS_TEMP_TOKEN);
}

export function setExpireTime(value) {
  localStorage.setItem(EXPIRE_TIME_STORAGE, value * 1000);
}

export function checkExpireTime() {
  const time = new Date().getTime();
  const expire = localStorage.getItem(EXPIRE_TIME_STORAGE) || 0;

  return time > expire;
}

export function setUserData(value) {
  localStorage.setItem(USER_DATA_STORAGE, JSON.stringify(value));
}

export function getUserData() {
  const retval = localStorage.getItem(USER_DATA_STORAGE);

  return JSON.parse(retval);
}

export function getRegional() {
  let data = localStorage.getItem(USER_DATA_STORAGE);

  if (!data) {
    return '';
  }

  data = JSON.parse(data);

  return data.regional ?? '';
}

export function getAdminId() {
  let data = localStorage.getItem(USER_DATA_STORAGE);

  if (!data) {
    return '';
  }

  data = JSON.parse(data);

  return data.adminId ?? '';
}

export function setUserIdMyptmActivation(userId){
  localStorage.setItem(USERID_MYPTM_ACTIVATION, JSON.stringify({ userId }));
}

export function getUserIdMyptmActivation() {
  const retval = localStorage.getItem(USERID_MYPTM_ACTIVATION);

  return JSON.parse(retval);
}

export function setUsersResetPassword(data){
  localStorage.setItem(USERS_RESET_PASSWORD, JSON.stringify(data));
}

export function getUsersResetPassword() {
  const retval = localStorage.getItem(USERS_RESET_PASSWORD);

  return JSON.parse(retval) || [];
}

export function setUserResetPassword(data){
  localStorage.setItem(USER_RESET_PASSWORD, JSON.stringify(data));
}

export function getUserResetPassword() {
  const retval = localStorage.getItem(USER_RESET_PASSWORD);

  return JSON.parse(retval);
}
