export const actionTypes = {
  FAILED: 'setting-merchant/failed',
  LOADING: 'setting-merchant/loading',
  SUCCESS: 'setting-merchant/success',
  FILTER: 'setting-merchant/filter',
  MESSAGE: 'setting-merchant/message',
  FORM: 'setting-merchant/form',
  RESET: 'setting-merchant/reset',
};

export const historyStatus = {
  'Disetujui': 'green',
  'Ditolak': 'red',
};
