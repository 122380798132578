import React from 'react';
import PropTypes from 'prop-types';
export default function IconWarning({ className, color }){
  return(
    <svg className={className} fill="none" height="38" viewBox="0 0 42 38" width="42" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8165 0.564328C20.896 -0.587498 23.4921 0.0817865 24.7677 2.04481L24.9333 2.32046L40.4513 30.337C40.8208 31.0043 41.0148 31.7545 41.0148 32.5173C41.0148 34.8991 39.1644 36.8486 36.8228 37.007L36.5148 37.0173H5.48325C4.72063 37.0173 3.97052 36.8235 3.30335 36.4541C1.22367 35.3025 0.413588 32.7469 1.4007 30.6241L1.54649 30.3374L17.0601 2.32093C17.4691 1.5822 18.0778 0.97347 18.8165 0.564328ZM21.0004 27.0036C19.8973 27.0036 19.0031 27.8978 19.0031 29.0009C19.0031 30.104 19.8973 30.9982 21.0004 30.9982C22.1035 30.9982 22.9977 30.104 22.9977 29.0009C22.9977 27.8978 22.1035 27.0036 21.0004 27.0036ZM20.9961 10.9961C19.9704 10.9965 19.1253 11.7689 19.0102 12.7636L18.9968 12.9968L19.0004 22.9986L19.0139 23.2318C19.1298 24.2265 19.9754 24.9982 21.0011 24.9979C22.0268 24.9975 22.8718 24.2251 22.987 23.2304L23.0004 22.9972L22.9968 12.9954L22.9832 12.7622C22.8674 11.7675 22.0217 10.9958 20.9961 10.9961Z" fill={color}/>
    </svg>
  );
}
IconWarning.defaultProps = {
  className: '',
  color: '#FFC107'
};
IconWarning.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
