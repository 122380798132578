import React, { useState , useMemo , useEffect } from 'react';
import PropTypes from 'prop-types';

export const AlertContext = React.createContext(null);
export const AppContext = React.createContext(null);
export const ModalContext = React.createContext(null);
export const ToastrContext = React.createContext(null);

export default function AppContextProvider({ children }) {
  const [alert, setAlert] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [content, setContent] = useState(null);
  const [modalCtx, setModalCtx] = useState(null);
  const [toastr, setToastr] = useState(null);

  const appValue = {
    breadcrumbs,
    setBreadcrumbs,
  };
  const alertValue = {
    alert,
    setAlert,
  };
  const modalValue = {
    content,
    modalCtx,
    setModalCtx,
    setContent
  };
  const toastrValue = useMemo(
    () => ({ toastr, setToastr }),
    [toastr, setToastr]
  );

  useEffect(() => {
    const toastrDuration =  setTimeout(() => {
      setToastr(null);
    }, 2000);

    return () => clearTimeout(toastrDuration);
  }, [toastr?.visible]);

  return (
    <AppContext.Provider value={appValue}>
      <AlertContext.Provider value={alertValue}>
        <ModalContext.Provider value={modalValue}>
          <ToastrContext.Provider value={toastrValue}>
            {children}
          </ToastrContext.Provider>
        </ModalContext.Provider>
      </AlertContext.Provider>
    </AppContext.Provider>
  );
}

AppContextProvider.defaultProps = {
  children: null,
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};

