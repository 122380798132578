import React from 'react';
import PropTypes from 'prop-types';
export default function IconFailed({ className, type }){
  return(
    type === 'small' ? (
      <svg className={className} fill="none" height="42" viewBox="0 0 40 42" width="40" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#FDF2F2" height="40" rx="20" width="40" y="2"/>
        <path d="M20 12C25.5228 12 30 16.4772 30 22C30 27.5228 25.5228 32 20 32C14.4772 32 10 27.5228 10 22C10 16.4772 14.4772 12 20 12ZM23.5303 18.4697L23.4462 18.3971C23.1852 18.2034 22.827 18.201 22.5636 18.3899L22.4697 18.4697L20 20.939L17.5303 18.4697L17.4462 18.3971C17.1852 18.2034 16.827 18.201 16.5636 18.3899L16.4697 18.4697L16.3971 18.5538C16.2034 18.8148 16.201 19.173 16.3899 19.4364L16.4697 19.5303L18.939 22L16.4697 24.4697L16.3971 24.5538C16.2034 24.8148 16.201 25.173 16.3899 25.4364L16.4697 25.5303L16.5538 25.6029C16.8148 25.7966 17.173 25.799 17.4364 25.6101L17.5303 25.5303L20 23.061L22.4697 25.5303L22.5538 25.6029C22.8148 25.7966 23.173 25.799 23.4364 25.6101L23.5303 25.5303L23.6029 25.4462C23.7966 25.1852 23.799 24.827 23.6101 24.5636L23.5303 24.4697L21.061 22L23.5303 19.5303L23.6029 19.4462C23.7966 19.1852 23.799 18.827 23.6101 18.5636L23.5303 18.4697L23.4462 18.3971L23.5303 18.4697Z" fill="#CF212A"/>
      </svg>
    ):(
      <svg className={className} fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
        <path clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20C0 31.0457 8.95431 40 20 40ZM26.3839 13.1161C26.872 13.6043 26.872 14.3957 26.3839 14.8839L21.7678 19.5L26.3839 24.1161C26.872 24.6043 26.872 25.3957 26.3839 25.8839C25.8957 26.372 25.1043 26.372 24.6161 25.8839L20 21.2678L15.3839 25.8839C14.8957 26.372 14.1043 26.372 13.6161 25.8839C13.128 25.3957 13.128 24.6043 13.6161 24.1161L18.2322 19.5L13.6161 14.8839C13.128 14.3957 13.128 13.6043 13.6161 13.1161C14.1043 12.628 14.8957 12.628 15.3839 13.1161L20 17.7322L24.6161 13.1161C25.1043 12.628 25.8957 12.628 26.3839 13.1161Z" fill="#FD3E38" fillRule="evenodd"/>
      </svg>
    )
  );
}
IconFailed.defaultProps = {
  className: '',
  type: 'full'
};
IconFailed.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['full', 'small'])
};
