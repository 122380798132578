import { CloseButton, Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import FormChangePassword from '../../forms/FormChangePassword';
import styles from './styles.scoped.css';

function ModalChangePassword({
  closeBtn,
  handleClose,
  open,
  handleSubmit,
  isLoading,
  subtitle,
  title,
  withCloseButton,
}) {
  return (
    <Modal
      centered
      onClose={withCloseButton ? handleClose : () => {}}
      opened={open}
      size={'md'}
      withCloseButton={false}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          {withCloseButton && (
            <span onClick={handleClose}>
              <CloseButton />
            </span>
          )}
        </div>
        {subtitle && <p>{subtitle}</p>}
        <FormChangePassword
          closeBtn={closeBtn}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
}


export default ModalChangePassword;

ModalChangePassword.defaultProps = {
  closeBtn: {
    style: 'outlined',
    text: 'Batal',
  },
  handleClose: () => {},
  handleSubmit: () => {},
  isLoading: false,
  open: false,
  subtitle: '',
  title: 'Perbarui Kata Sandi',
  withCloseButton: true,
};

ModalChangePassword.propTypes = {
  closeBtn: PropTypes.object,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  withCloseButton: PropTypes.bool,
};
