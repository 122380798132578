import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SessionExpiredContext = createContext();

export const SessionExpiredProvider = ({ children }) => {
  const [sessionExpired, setSessionExpired] = useState(false);

  const openModalOtp = () => setSessionExpired(true);
  const closeModalOtp = () => {
    setSessionExpired(false);
  };

  return (
    <SessionExpiredContext.Provider value={{ sessionExpired, openModalOtp, closeModalOtp }}>
      {children}
    </SessionExpiredContext.Provider>
  );
};

export const useSessionExpired = () => useContext(SessionExpiredContext);

SessionExpiredProvider.defaultProps = {
  children: null,
};

SessionExpiredProvider.propTypes = {
  children: PropTypes.node,
};
