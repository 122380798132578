export const CHANGED = 'ResetPassword/changed';
export const LOADING = 'reset/password/loading';
export const MESSAGE = 'reset/password/message';
export const FAILED = 'reset/password/failed';
export const FETCHED = 'reset/password/fetched';

export const REMINDER_DURATION = 300;

export const SUCCESS_MESSAGE =
  'Anda dapat menggunakan Password baru untuk masuk ke akun Anda.';
export const ERROR_MESSAGE =
  'Terjadi kesalahan pada sistem saat mengubah Password. Tenang, Anda dapat mencoba ubah Password kembali.';
