/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconError({ className }){
  return (
    <svg
      className={className}
      fill="none"
      height="11"
      viewBox="0 0 12 11"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.97 0.5C6.21058 0.500165 6.44756 0.558387 6.66082 0.669719C6.87409 0.781052 7.05733 0.942207 7.195 1.1395L7.249 1.2215L11.4645 8.2585C11.5908 8.4775 11.6595 8.72497 11.6641 8.97775C11.6688 9.23052 11.6092 9.48034 11.491 9.70383C11.3728 9.92733 11.1999 10.1172 10.9884 10.2556C10.7768 10.3941 10.5336 10.4766 10.2815 10.4955L10.1815 10.5H1.7535C1.5011 10.497 1.25356 10.4301 1.03398 10.3056C0.814398 10.1811 0.629916 10.003 0.497732 9.78797C0.365549 9.57292 0.289969 9.32791 0.278038 9.07577C0.266107 8.82363 0.318215 8.57257 0.429502 8.346L0.480002 8.2515L4.6925 1.219C4.82574 0.999507 5.01328 0.818044 5.23704 0.692107C5.4608 0.566171 5.71323 0.500008 5.97 0.5ZM6.005 7.5L5.9415 7.5035C5.81998 7.51795 5.70797 7.57648 5.62671 7.66798C5.54544 7.75949 5.50056 7.87762 5.50056 8C5.50056 8.12238 5.54544 8.24051 5.62671 8.33202C5.70797 8.42352 5.81998 8.48205 5.9415 8.4965L6 8.5L6.0635 8.4965C6.18503 8.48205 6.29703 8.42352 6.3783 8.33202C6.45956 8.24051 6.50444 8.12238 6.50444 8C6.50444 7.87762 6.45956 7.75949 6.3783 7.66798C6.29703 7.57648 6.18503 7.51795 6.0635 7.5035L6.005 7.5ZM6 3.5C5.87754 3.50002 5.75933 3.54498 5.66782 3.62636C5.5763 3.70774 5.51783 3.81987 5.5035 3.9415L5.5 4V6L5.5035 6.0585C5.51796 6.18002 5.57648 6.29203 5.66798 6.37329C5.75949 6.45456 5.87762 6.49944 6 6.49944C6.12238 6.49944 6.24051 6.45456 6.33202 6.37329C6.42352 6.29203 6.48205 6.18002 6.4965 6.0585L6.5 6V4L6.4965 3.9415C6.48217 3.81987 6.42371 3.70774 6.33219 3.62636C6.24067 3.54498 6.12247 3.50002 6 3.5Z"
        fill="#CF212A"
      />
    </svg>
  );
}
IconError.defaultProps = {
  className: ''
};
IconError.propTypes = {
  className: PropTypes.string
};
