import PropTypes from 'prop-types';
import React from 'react';
export default function IconSuccess({ className, type }){
  return(
    type === 'small' ? (
      <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#F0FFF1" height="40" rx="20" width="40"/>
        <path d="M20 10C25.5228 10 30 14.4772 30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10ZM23.2197 16.9697L18.75 21.4393L16.7803 19.4697C16.4874 19.1768 16.0126 19.1768 15.7197 19.4697C15.4268 19.7626 15.4268 20.2374 15.7197 20.5303L18.2197 23.0303C18.5126 23.3232 18.9874 23.3232 19.2803 23.0303L24.2803 18.0303C24.5732 17.7374 24.5732 17.2626 24.2803 16.9697C23.9874 16.6768 23.5126 16.6768 23.2197 16.9697Z" fill="#44AB4A"/>
      </svg>
    ): (
      <svg className={className} fill="none" height="40" viewBox="0 0 41 40" width="41" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5 0C31.5457 0 40.5 8.9543 40.5 20C40.5 31.0457 31.5457 40 20.5 40C9.4543 40 0.5 31.0457 0.5 20C0.5 8.9543 9.4543 0 20.5 0ZM26.9393 13.9393L18 22.8787L14.0607 18.9393C13.4749 18.3536 12.5251 18.3536 11.9393 18.9393C11.3536 19.5251 11.3536 20.4749 11.9393 21.0607L16.9393 26.0607C17.5251 26.6464 18.4749 26.6464 19.0607 26.0607L29.0607 16.0607C29.6464 15.4749 29.6464 14.5251 29.0607 13.9393C28.4749 13.3536 27.5251 13.3536 26.9393 13.9393Z" fill="#44AB49"/>
      </svg>
    )
  );
}
IconSuccess.defaultProps = {
  className: '',
  type: 'full'
};
IconSuccess.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['full', 'small'])
};
