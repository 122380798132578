import axios from 'axios';
import { clearStorages, getTempToken, getToken } from './storage';
import { basicAuthRoutes } from './api';
import { BASE_URL, BASIC_KEY } from '../../env';
import { openModalOtp } from '../contexts/sessionExpiredHelper';

const fetch = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

fetch.interceptors.request.use(function (config) {
  if (config.method === 'get' && config.url.includes('/faqs/v1/myptm/')) {
    config.headers.Authorization = `Basic ${BASIC_KEY}`;
  } else if (
    !config.url.includes('/faqs/v1/myptm/') &&
    basicAuthRoutes.find((route) => config.url.includes(route))
    && !config.isBearer
  ) {
    config.headers.Authorization = `Basic ${BASIC_KEY}`;
  } else {
    const token = getToken();
    if (token && !config.isTempToken) {
      config.headers.Authorization = `Bearer ${token}`;
    }else{
      const tempToken = config.tempToken || getTempToken();
      config.headers.Authorization = `Bearer ${tempToken}`;
    }
  }
  return config;
});

const defaultError = {
  code: 500,
  status: 'error',
  message:
    'Terjadi kesalahan teknis dalam sistem. Silahkan coba beberapa saat lagi.',
};

fetch.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (err) {
    return new Promise((resolve, reject) => {
      if (!err.response) reject(defaultError);
      else if (err.response.status === 401 && err.response.data.message === 'Session Expired') {
        openModalOtp();
      }else if (err.response.status === 401 || err.response.status === 403) {
        reject(err.response);
        if(err.config.isTempToken){
          return err?.response?.data;
        }else{
          clearStorages();
          location.href = '/dc/login';
        }
      } else if (!err.response.data) reject(defaultError);
      else reject(err.response.data);
    });
  }
);

export const getTokenSource = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return source;
};

export default fetch;
