import { changePassword, countdown } from '../../utils/api';
import { clearStorages } from '../../utils/storage';
import { SUCCESS, FAILED, LOADING } from './constants';

export function fetchChangePassword(payload, isExpired=false) {
  const key = 'changePassword';
  return async (dispatch) => {
    dispatch(loadingAction(key, true));
    try {
      const data = await changePassword(payload);
      if (data.success) {
        dispatch(successAction(key, data, isExpired));
        setTimeout(() => {
          clearStorages();
          location.href = '/dc/login';
        }, 2000);
      }
    } catch (error) {
      if(error.code === 400 && isExpired){
        setTimeout(() => {
          clearStorages();
          location.href = '/dc/login';
        }, 2000);
      }
      dispatch(failedAction(key, error.message, error.code, error.data, isExpired));
    } finally {
      dispatch(loadingAction(key, false));
    }
  };
}

export function fetchCountDown(){
  const key = 'countdown';
  return async(dispatch) => {
    dispatch(loadingAction(key, true));
    try{
      const data = await countdown();
      dispatch(loadingAction(key, false));
      dispatch(successAction(key, data));
    }catch(error){
      dispatch(failedAction(key, error.message, error.code, error.data));
    }
  };
}

export function resetAction() {
  return async (dispatch) => {
    dispatch({ type: 'RESET' });
  };
}

export function failedAction(key, message, code, data, isExpired) {
  return { type: FAILED, message, code, data, key, isExpired };
}

export function loadingAction(key, isLoading) {
  return { type: LOADING, isLoading, key };
}

export function successAction(key, data, isExpired) {
  return { type: SUCCESS, data, key, isExpired };
}
