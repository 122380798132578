import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../elements/Button';
import { PinInput } from '@mantine/core';
import styles from './styles.scoped.css';
import IconError from '../../icons/IconError';
import { useSelector } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import { formatCountdown } from '../../../utils/format';
import { useMediaQuery } from '@mantine/hooks';

const TotpForm = (props) => {
  const {
    handleSubmit, handleReset, withCancelButton, buttonSubmitLabel, setModal, handledClose
  } = props;
  const [pin, setPin] = useState(0);
  const { isLoading, message, countDownTime, code } = useSelector((s) => s.mfa);
  const matches = useMediaQuery('(max-width: 768px)');
  const dateNow = new Date();
  const { minutes, seconds, restart } =
    useTimer({ expiryTimestamp: dateNow,
      onExpire: () => handleReset(),
      autoStart: false
    });

  const btnDisabled = isLoading || pin?.length !== 6 || code === 429;
  const pinDisabled = isLoading || code === 429;

  const formSubmit = useCallback(
    (e) => {
      e.preventDefault();
      handleSubmit(pin);
    },
    [pin]
  );

  const onChange = (value) => {
    setPin(value);
    handleReset();
  };

  useEffect(() => {
    if (code === 429) {
      restart(countDownTime);
    }
    if( code === 401 || code === 403){
      setModal(true);
    }
  }, [code]);

  return (
    <form className={styles.root} onSubmit={(e) => formSubmit(e)}>
      <label>Kode Aplikasi Authenticator</label>
      <PinInput
        autoFocus
        className={`${styles['pin-input']} ${!matches ? styles['pin-input-desktop'] : ''}`}
        disabled={pinDisabled}
        error={message?.length > 0 && code !== 200}
        length={6}
        onChange={(value) => onChange(value)}
        placeholder=""
        size={matches && 'lg'}
        type="number"
      />
      {(message?.length > 0 && code !== 200) && (
        <small className={styles.error}>
          <IconError />
          {message.map((m, i)=> code === 429 && i === 0 ?
            `${m} ${formatCountdown(minutes, seconds)}` :
            m)
          }
        </small>
      )}
      <small>
        Masukkan 6 digit kode yang dibuat oleh aplikasi authenticator.
      </small>
      <div className={styles.btnContainer}>
        {withCancelButton && (
          <Button
            disabled={isLoading}
            onClick={handledClose}
            style={'outlined'}
            type={'button'}
          >
          Batal
          </Button>
        )}
        <Button disabled={btnDisabled} style={'blue'}>
          {isLoading ? 'Mengirim...' : buttonSubmitLabel}
        </Button>
      </div>
    </form>
  );
};

export default TotpForm;

TotpForm.defaultProps = {
  buttonSubmitLabel: 'Masuk',
  handledClose: () => {},
  handleReset: () => {},
  handleSubmit: () => {},
  isLoading: false,
  message: '',
  setModal: () => {},
  withCancelButton: false,
};

TotpForm.propTypes = {
  buttonSubmitLabel: PropTypes.string,
  handledClose: PropTypes.func,
  handleReset: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  setModal: PropTypes.func,
  withCancelButton: PropTypes.bool,
};
