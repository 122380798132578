/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconSettingBusinessType({ className }){
  return(
    <svg className={className} fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 26.25H26.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M3.75 8.75V10C3.75 10.9946 4.14509 11.9484 4.84835 12.6517C5.55161 13.3549 6.50544 13.75 7.5 13.75C8.49456 13.75 9.44839 13.3549 10.1517 12.6517C10.8549 11.9484 11.25 10.9946 11.25 10M3.75 8.75H26.25M3.75 8.75L6.25 3.75H23.75L26.25 8.75M11.25 10V8.75M11.25 10C11.25 10.9946 11.6451 11.9484 12.3483 12.6517C13.0516 13.3549 14.0054 13.75 15 13.75C15.9946 13.75 16.9484 13.3549 17.6517 12.6517C18.3549 11.9484 18.75 10.9946 18.75 10M18.75 10V8.75M18.75 10C18.75 10.9946 19.1451 11.9484 19.8483 12.6517C20.5516 13.3549 21.5054 13.75 22.5 13.75C23.4946 13.75 24.4484 13.3549 25.1517 12.6517C25.8549 11.9484 26.25 10.9946 26.25 10V8.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path d="M6.25 26.25V13.5625" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path d="M23.75 26.25V13.5625" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path d="M11.25 26.25V21.25C11.25 20.587 11.5134 19.9511 11.9822 19.4822C12.4511 19.0134 13.087 18.75 13.75 18.75H16.25C16.913 18.75 17.5489 19.0134 18.0178 19.4822C18.4866 19.9511 18.75 20.587 18.75 21.25V26.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>

  );
}
IconSettingBusinessType.defaultProps = {
  className: ''
};
IconSettingBusinessType.propTypes = {
  className: PropTypes.string
};
