import React from 'react';
import PropTypes from 'prop-types';
import Password from '../../fields/Password';
import { Field } from 'redux-form';
import Button from '../../elements/Button';
import styles from './styles.scoped.css';
import { useSelector } from 'react-redux';
import { validate } from './validate';
import LoadingBars from '../../elements/LoadingBars';
import IconWarning from '../../icons/IconWarning';

function FormChangePassword({ closeBtn, handleSubmit, handleClose, isLoading }){
  const { values } = useSelector((state) => state.form.changePasswordForm);
  const [strength, setStrength] = React.useState(false);
  const isValidate =
    validate(values).password ||
    validate(values).newPassword ||
    validate(values).confirmPassword;

  const onSubmit = async (e) => {
    e.preventDefault();
    handleSubmit(values);
  };
  return(
    <form onSubmit={onSubmit}>
      <Field
        component={Password}
        inputProps={{
          label: 'Kata Sandi',
          placeholder: 'Masukkan kata sandi saat ini',
        }}
        name="password"/>
      <Field
        component={Password}
        errorIcon={<IconWarning className={styles['error-icon']} color="#CF212A" />}
        inputProps={{
          label: 'Kata Sandi Baru',
          placeholder: 'Masukkan kata sandi baru',
          info: 'Gunakan gabungan karakter huruf, angka & simbol untuk kata sandi yang lebih baik',
        }}
        name="newPassword"
        setStrength={setStrength}
        withStrength={true} />
      <Field
        component={Password}
        inputProps={{
          label: 'Konfirmasi Kata Sandi Baru',
          placeholder: 'Masukkan kembali kata sandi baru',
        }}
        name="confirmPassword"
        withStrength={false} />
      <div className={styles.containerBtn}>
        <Button onClick={handleClose} style={closeBtn.style} type="button">{closeBtn.text}</Button>
        <Button
          className={styles.btn}
          disabled={isValidate || strength < 5 || isLoading}
          style="blue"
          type="submit"
          variant="secondary">
          {isLoading && <LoadingBars size={1} />}
          <span>Perbarui Kata Sandi</span>
        </Button>
      </div>
    </form>
  );
}

export default FormChangePassword;

FormChangePassword.defaultProps = {
  closeBtn: {
    style: 'outlined',
    text: 'Batal',
  },
  handleClose: () => {},
  handleSubmit: () => {},
  isLoading: false,
};

FormChangePassword.propTypes = {
  closeBtn: PropTypes.object,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
