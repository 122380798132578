export const initialValues =  {
  password: '',
  newPassword: '',
  confirmPassword: '',
};

export const validate = (values) => {
  const {
    confirmPassword,
    newPassword,
    password,
  } = values;
  const errors = {};
  if (password.length === 0) {
    errors.password = 'Kata sandi saat ini harus diisi';
  }
  if (newPassword.length === 1) {
    errors.newPassword = 'Kata sandi baru harus diisi';
  }
  if (newPassword && newPassword.length < 8) {
    errors.newPassword = 'Kata sandi baru minimal 8 karakter';
  }

  if (!confirmPassword) {
    errors.confirmPassword = 'Konfirmasi kata sandi baru harus diisi';
  }

  if (confirmPassword !== newPassword) {
    errors.confirmPassword = 'Konfirmasi kata sandi baru tidak sesuai';
  }
  if (password === newPassword) {
    errors.newPassword = 'Kata sandi tidak boleh sama dengan kata sandi lama';
  }
  return errors;

};
