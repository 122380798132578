/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useLocation } from 'react-router-dom';
import IconAktivasiManual from '../../icons/IconAkativasiManual';
import IconAturHET from '../../icons/IconAturHET';
import IconAudit from '../../icons/IconAudit';
import IconLogSyns from '../../icons/IconLogSyns';
import IconMyptmActivation from '../../icons/IconMyptmActivation';
import IconNotification from '../../icons/IconNotification';
import IconPermission from '../../icons/IconPermission';
import IconRegistrasiPangkalan from '../../icons/IconRegistrasiPangkalan';
import IconRole from '../../icons/IconRole';
import IconSettingFaq from '../../icons/IconSettingFaq';
import IconTerm from '../../icons/IconTerm';
import IconUser from '../../icons/IconUser';
import IconVerification from '../../icons/IconVerification';
import IconSettingMerchant from '../../icons/IconsettingMerchant';
import styles from './styles.scoped.css';
import IcUpdateStock from '../../icons/IcUpdateStock';
import IconMerchantNoOnboard from '../../icons/IconMerchantNoOnboard';
import { IconProfile } from '../../icons/IconProfile';
import IconSettingBusinessType from '../../icons/IconSettingBusinessType';

export function renderIconBreadcrumbs() {
  const { pathname } = useLocation();
  const listIcon = [
    {
      icon: <IconRegistrasiPangkalan className={styles.breadCrumbsIcon} />,
      path: '/registrasi-pangkalan',
    },
    {
      icon: <IconAturHET className={styles.breadCrumbsIcon} />,
      path: '/price',
    },
    {
      icon: <IconSettingFaq className={styles.breadCrumbsIcon} />,
      path: '/faq',
    },
    {
      icon: <IconAudit className={styles.breadCrumbsIcon} />,
      path: '/user-management/audit-log',
    },
    {
      icon: <IconVerification className={styles.breadCrumbsIcon} />,
      path: '/merchant/verifikasi-data-merchant',
    },
    {
      icon: <IconVerification className={styles.breadCrumbsIcon} />,
      path: '/merchant/detail-verifikasi-data-merchant',
    },
    {
      icon: <IconLogSyns className={styles.breadCrumbsIcon} />,
      path: '/merchant/sinkronisasi-merchant',
    },
    {
      icon: <IconSettingMerchant className={styles.breadCrumbsIcon} />,
      path: '/merchant/setting-data-merchant',
    },
    {
      icon: <IconMerchantNoOnboard className={styles.breadCrumbsIcon} />,
      path: '/merchant/merchant-not-onboarding',
    },
    {
      icon: <IconSettingMerchant className={styles.breadCrumbsIcon} />,
      path: '/merchant/detail-setting-data-merchant',
    },
    {
      icon: <IconSettingMerchant className={styles.breadCrumbsIcon} />,
      path: '/merchant/edit-setting-data-merchant',
    },
    {
      icon: <IconMyptmActivation className={styles.breadCrumbsIcon} />,
      path: '/merchant/aktivasi-mypertamina',
    },
    {
      icon: <IcUpdateStock classname={styles.breadCrumbsIcon} />,
      path: '/merchant/update-stock-merchant',
    },
    {
      icon: <IconUser className={styles.breadCrumbsIcon} />,
      path: '/user-management/user',
    },
    {
      icon: <IconPermission className={styles.breadCrumbsIcon} />,
      path: '/user-management/permission',
    },
    {
      icon: <IconRole className={styles.breadCrumbsIcon} />,
      path: '/user-management/role',
    },
    {
      icon: <IconAktivasiManual className={styles.breadCrumbsIcon} />,
      path: '/merchant/aktivasi-pencatatan-manual',
    },
    {
      icon: <IconTerm className={styles.breadCrumbsIcon} />,
      path: '/term-condition',
    },
    {
      icon: <IconNotification className={styles.breadCrumbsIcon} />,
      path: '/notification',
    },
    {
      icon: <IconProfile className={styles.breadCrumbsIcon} />,
      path: '/profile',
    },
    {
      icon: <IconSettingBusinessType className={styles.iconSettingBusinessType} />,
      path: '/business-type',
    }
  ];

  return (
    <>
      {listIcon.map((val) => {
        if (pathname.includes(val.path)) {
          return val.icon;
        }
      })}
    </>
  );
}
