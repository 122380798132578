import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scoped.css';
import IconWarning from '../../icons/IconWarning';
import { IconCheckCircle } from '../../icons/IconCheckCircle';
import { number, specialChars, upperCaseLetter } from '../../../utils/validation';

function PasswordStrength({ score, ...props }) {
  const scoreText = {
    0: 'Lemah',
    1: 'Lemah',
    2: 'Lemah',
    3: 'Cukup',
    4: 'Kuat',
    5: 'Sangat kuat',
  };

  const rules = {
    minLength: {
      rule: (val) => val.length >= 8,
      info: '8-16 karakter',
    },
    hasUpperCase: {
      rule: (val) => upperCaseLetter(val),
      info: 'Ada huruf besar',
    },
    hasNumber: {
      rule: (val) => number(val),
      info: 'Ada angka',
    },
    hasSymbol: {
      rule: (val) => specialChars(val),
      info: 'Ada simbol',
    },
  };

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.label}>Kekuatan kata sandi:&nbsp;
          <span className={styles[`pass${score}`]}>{scoreText[score]}</span></span>
        <div {...props} className={styles.bar}>
          <div className={styles[`pass${score}`]}/>
        </div>
      </div>
      <div className={styles.rulesInfo}>
        {Object.keys(rules).map((key) => {
          const passed = rules[key].rule(props.value);
          return(
            <div key={rules[key].info}>
              <span className={passed ? styles[`passed`] : styles['failed']}>
                {passed ? <IconCheckCircle/> : <IconWarning className={styles.icon} color={'#CF212A'} />} {rules[key].info}</span>
            </div>
          );})}
      </div>
    </div>
  );
}

PasswordStrength.defaultProps = {
  score: 0,
  value: '',
};

PasswordStrength.propTypes = {
  score: PropTypes.number,
  value: PropTypes.string,
};

export default PasswordStrength;
