import React from 'react';
import PropTypes from 'prop-types';

export const IconProfile = ({ className }) => (
  <svg className={className} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.24888 13.9953L10.1253 13.9951C9.77611 14.4178 9.55084 14.9466 9.50509 15.5259L9.49646 15.7454V20.2454C9.49646 20.9103 9.73247 21.5202 10.1253 21.9957L9.99646 21.9964C6.57549 21.9964 4.05253 20.9243 2.51037 18.7599C2.17841 18.294 2 17.7362 2 17.1642V16.2442C2 15.0022 3.00686 13.9953 4.24888 13.9953ZM14.7465 11.4954H17.7465C18.3937 11.4954 18.926 11.9872 18.99 12.6176L18.9965 12.7454L18.996 13.995L20.2465 13.9954C21.213 13.9954 21.9965 14.7789 21.9965 15.7454V20.2454C21.9965 21.2119 21.213 21.9954 20.2465 21.9954H12.2465C11.28 21.9954 10.4965 21.2119 10.4965 20.2454V15.7454C10.4965 14.7789 11.28 13.9954 12.2465 13.9954L13.496 13.995L13.4965 12.7454C13.4965 12.0982 13.9883 11.5658 14.6187 11.5018L14.7465 11.4954H17.7465H14.7465ZM17.4965 12.9954H14.9965L14.996 13.995H17.496L17.4965 12.9954ZM9.99646 2C12.7579 2 14.9965 4.23858 14.9965 7C14.9965 9.76142 12.7579 12 9.99646 12C7.23503 12 4.99646 9.76142 4.99646 7C4.99646 4.23858 7.23503 2 9.99646 2Z" fill="currentColor"/>
  </svg>
);

IconProfile.defaultProps = {
  className: '',
};

IconProfile.propTypes = {
  className: PropTypes.string,
};
