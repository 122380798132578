import {
  setToken,
  setUserData,
  setExpireTime,
  getUserData,
} from '../../utils/storage';
import { totpVerify, mfaRegister, totpProtectedResource } from '../../utils/api';
import { FAILED, LOADING, RESET, SET_COUNT_TIME, SUCCESS } from './constants';

export function totpSend(data) {
  return (dispatch) => {
    dispatch(loadingAction(true));

    const isMfaActive = getUserData().isMfaActive;
    const api = isMfaActive ? totpVerify : mfaRegister;

    api({ otp: data })
      .then((res) => {
        dispatch(loadingAction(false));
        if (res.data.accessToken) {
          dispatch(totpSuccessAction());
          const { accessToken, ...userData } = res.data;
          const { exp } = JSON.parse(atob(accessToken.split('.')[1]));
          setToken(accessToken);
          setExpireTime(exp);
          setUserData(userData);
          const redirect = sessionStorage.getItem('redirect');
          if (redirect) {
            sessionStorage.removeItem('redirect');
            location.replace(redirect);
          } else {
            location.href = '/dc/first-login';
          }
        } else {
          dispatch(totpFailedAction({ message: 'You are not allowed to access' }));
        }
      })
      .catch((err) => {
        let message;
        if (err.code === 429) {
          message =  [ 'Anda salah memasukkan kode autentikasi sebanyak 5 kali, tunggu selama ',
            ' untuk mencoba lagi.' ];
        } else if (err.code === 400) {
          message = ['Kode tidak sesuai'];
        }else if (err?.data?.code === 401 || err?.data?.code === 403){
          message = ['Akses token telah berakhir, silahkan login kembali'];
        }else {
          message = [err.message];
        }
        const dataCountDown = err.code === 429 ? err.data.countDownTime : 0;
        const countDownTime = new Date();
        countDownTime.setSeconds(countDownTime.getSeconds() + dataCountDown);
        const payload = { message, countDownTime, code: err.code || err?.data?.code };
        dispatch(loadingAction(false));
        dispatch(totpFailedAction(payload));
      });
  };
}

export function fetchVerifyOtp(params){
  return async (dispatch) => {
    dispatch(loadingAction(true));
    try {
      const data = await totpProtectedResource(params);
      const payload = { code: data.code, message: [data.message] };
      dispatch(successAction(payload));
      dispatch(loadingAction(false));
    } catch (err) {
      let message;
      if (err.code === 429) {
        message =  [ 'Anda salah memasukkan kode autentikasi sebanyak 5 kali, tunggu selama ',
          ' untuk mencoba lagi.' ];
      } else if (err.code === 400) {
        message = ['Kode tidak sesuai'];
      }else if (err?.data?.code === 401 || err?.data?.code === 403){
        message = ['Akses token telah berakhir, silahkan login kembali'];
      }else {
        message = [err.message];
      }
      const dataCountDown = err.code === 429 ? err.data.countDownTime : 0;
      const countDownTime = new Date();
      countDownTime.setSeconds(countDownTime.getSeconds() + dataCountDown);
      const payload = { message, countDownTime, code: err.code || err?.data?.code };
      dispatch(totpFailedAction(payload));
      dispatch(loadingAction(false));
    }
  };
}

export function totpReset () {
  return (dispatch) => {
    dispatch(totpResetAction());
  };
}

function successAction(payload) {
  return { type: SUCCESS, ...payload };
}

export function setCountTime(payload) {
  return { type: SET_COUNT_TIME, ...payload };
}

function loadingAction(isLoading) {
  return { type: LOADING, isLoading };
}

function totpFailedAction(payload) {
  return { type: FAILED, ...payload };
}

function totpSuccessAction() {
  return { type: SUCCESS };
}

function totpResetAction() {
  return { type: RESET };
}
