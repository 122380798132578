import moment from 'moment';

moment.locale('id');

export const thousand = (val = 0) => {
  val =
    val && (typeof val === 'string' || typeof val === 'number')
      ? parseFloat(val)
      : 0;
  if (val % 1 > 0) {
    val = val.toFixed(2);
  }
  return val
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    .replace(/,00$/, '');
};

export const date = (value = '', format = 'DD MMM YYYY') => {
  return value ? moment(value, 'YYYY-MM-DD').format(format) : value;
};

export const formatDateTime = (value = '', format = 'DD MMM YYYY HH:mm') => {
  return moment(value).isValid()
    ? moment(value).format(format) + ' WIB'
    : value;
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const toDateObject = (value = '') => {
  return moment(value).isValid() ? moment(value).toDate() : null;
};

export const toUpperCase = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const mapping = (data) => {
  return data?.filter((data) => data.value === '*').length === 1 ? []
    : data?.filter((data) => data.value !== '*').map((data) => data.value);
};

export const mappingById = (data) => {
  if(data.length > 0){
    return data?.filter((data) => data === '*').length === 1 ? ''
      : data?.filter((data) => data !== '*');
  }else {
    return '';
  }
};

export function formatBytes(bytes, decimals, binaryUnits) {
  if(bytes === 0) {
    return '0 Bytes';
  }
  let unitMultiple = (binaryUnits) ? 1024 : 1000;
  let unitNames = (unitMultiple === 1024) ?
  // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
    ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']:
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
  return parseFloat((bytes / Math.pow(unitMultiple, unitChanges)).toFixed(decimals || 0)) + ' ' + unitNames[unitChanges];
}

export const formatCountdown = (minutes, seconds) => {
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const countDownTime = `${formattedMinutes}:${formattedSeconds}`;
  return countDownTime;
};

export const maskEmail = (email) => {
  if (!email) return '';
  const [localPart, domainPart] = email.split('@');
  if (localPart.length < 3) return email;
  const maskedLocalPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
  return `${maskedLocalPart}@${domainPart}`;
};
