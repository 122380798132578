import { FAILED, LOADING, SUCCESS } from './constants';

export const initialState = {
  dataSubmitPermission: false,
  dataSubmitUpdatePermission: false,
  dataPermission : {
    data: [],
    meta: {
      page: 1,
      totalPage: 1,
      totalData: 0,
    },
  },
  isLoadingSubmitPermission: false,
  isLoadingPermission: false,
  messageSubmitPermission: '',
  messageSubmitUpdatePermission: '',
  messagePermission : '',
  loading: false,
  message: '',
  // download: {
  //   data: {
  //     excelLink: '',
  //     pdfLink: '',
  //   },
  //   loading: false,
  //   message: '',
  // },
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;
  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    default:
      return state;
  }
}
