import React from 'react';
import IcUpdateStock from '../components/icons/IcUpdateStock';
import IconAktivasiManual from '../components/icons/IconAkativasiManual';
import IconAturHET from '../components/icons/IconAturHET';
import IconAudit from '../components/icons/IconAudit';
import IconLogSyns from '../components/icons/IconLogSyns';
import IconMerchantNoOnboard from '../components/icons/IconMerchantNoOnboard';
import IconMyptmActivation from '../components/icons/IconMyptmActivation';
import IconNotification from '../components/icons/IconNotification';
import IconPermission from '../components/icons/IconPermission';
import IconRegistrasiPangkalan from '../components/icons/IconRegistrasiPangkalan';
import IconRole from '../components/icons/IconRole';
import IconSettingFaq from '../components/icons/IconSettingFaq';
import IconTerm from '../components/icons/IconTerm';
import IconUser from '../components/icons/IconUser';
import IconVerification from '../components/icons/IconVerification';
import IconSettingMerchant from '../components/icons/IconsettingMerchant';
import pages from '../pages';
import IconSettingBusinessType from '../components/icons/IconSettingBusinessType';
import styles from './routeBuilder.scoped.css';

export const router = [{
  serialNumber: 1,
  path: '/merchant/registrasi-pangkalan',
  icon: <IconRegistrasiPangkalan style={{ background: '#0f6eb5' }} />,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/registrasi-pangkalan/registrasi-sekaligus',
      element: pages.RegistrasiSekaligusMerchant,
      disabled: true,
    },{
      path: '/merchant/registrasi-pangkalan',
      element: pages.RegistrasiPangkalan,
    },{
      path: '/merchant/registrasi-pangkalan/:id/:type',
      element: pages.DetailRegistrasiMerchant
    },
  ]
},{
  serialNumber: 2,
  path: '/merchant/sinkronisasi-merchant',
  icon: <IconLogSyns/>,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/sinkronisasi-merchant',
      element: pages.SinkronisasiMerchant,
    },{
      path: '/merchant/sinkronisasi-merchant/:id',
      element: pages.DetailSinkronisasiMerchant
    },
  ]
}
,{
  serialNumber: 3,
  path: '/merchant/verifikasi-data-merchant',
  icon: <IconVerification />,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/verifikasi-data-merchant',
      element: pages.VerificationRegistrationMerchant
    },{
      path: '/merchant/detail-verifikasi-data-merchant',
      element: pages.DetailVerfication
    },
  ]
},
{
  serialNumber: 4,
  path: '/merchant/setting-data-merchant',
  icon: <IconSettingMerchant />,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/setting-data-merchant',
      element: pages.SettingMerchant
    },{
      path: '/merchant/setting-data-merchant/agent-history/:id',
      element: pages.AgenHistory
    },{
      path: '/merchant/detail-setting-data-merchant/:id',
      element: pages.DetailSetting
    },{
      path: '/merchant/edit-setting-data-merchant/:id',
      element: pages.EditMerchant,
      disabled: true,
    },{
      path: '/merchant/setting-data-merchant-update/:id',
      element: pages.EditMerchant,
    }
  ]
},
{
  serialNumber: 5,
  path: '/merchant/merchant-not-onboarding',
  icon: <IconMerchantNoOnboard />,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/merchant-not-onboarding',
      element: pages.MerchantNotOnboarding
    },
  ]
},{
  serialNumber: 6,
  path: '/merchant/aktivasi-mypertamina',
  icon: <IconMyptmActivation />,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/aktivasi-mypertamina',
      element: pages.MyPertaminaActivation
    },{
      path: '/merchant/aktivasi-mypertamina/detail/:id',
      element: pages.MyPertaminaActivationDetail
    },{
      path: '/merchant/aktivasi-mypertamina/bulk',
      element: pages.MyPertaminaActivationBulk
    },
  ]
},{
  serialNumber: 7,
  path: '/merchant/aktivasi-pencatatan-manual',
  icon: <IconAktivasiManual/>,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/aktivasi-pencatatan-manual',
      element: pages.AktivasiPencatatanManual
    }
  ]
},{
  serialNumber: 8,
  path: '/merchant/update-stock-merchant',
  icon: <IcUpdateStock/>,
  menu: 'management-merchant',
  elements: [
    {
      path: '/merchant/update-stock-merchant',
      element: pages.UpdateStockMerchant
    },
    {
      path: '/merchant/update-stock-merchant/upload',
      element: pages.UploadStockMerchant
    }
  ]
},{
  path: '/price',
  icon: <IconAturHET />,
  menu: 'price',
  elements: [
    {
      path: '/price',
      element: pages.SettingHarga
    },{
      path: '/price/set',
      element: pages.FormSettingHarga
    },{
      path: '/price/setting-sekaligus',
      element: pages.SettingHargaSekaligus
    }
  ]
},{
  path: '/faq',
  icon: <IconSettingFaq />,
  menu: 'setting-map',
  elements: [
    {
      path: '/faq',
      element: pages.Faq
    },{
      path: '/faq/set',
      element: pages.CreateFaq
    },{
      path: '/faq/edit',
      element: pages.UpdateFaq
    }
  ]
},{
  path: '/term-condition',
  icon: <IconTerm/>,
  menu: 'setting-map',
  elements: [
    {
      path: '/term-condition',
      element: pages.TermCondition
    },{
      path: '/term-condition/update',
      element: pages.TermConditionUpdate
    },
  ]
},{
  path: '/notification',
  icon: <IconNotification/>,
  menu: 'setting-map',
  elements: [
    {
      path: '/notification',
      element: pages.Notification
    },{
      path: '/notification/add',
      element: pages.AddNotification
    },{
      path: '/notification/edit/:id',
      element: pages.EditNotification
    }
  ]
},{
  path: '/business-type',
  icon: <IconSettingBusinessType className={styles.iconSettingBusinessType} />,
  menu: 'setting-map',
  elements: [
    {
      path: '/business-type',
      element: pages.SettingBusinessType
    },
  ]
},{
  serialNumber: 1,
  path: '/user-management/user',
  icon: <IconUser/>,
  menu: 'management-user',
  elements: [
    {
      path: '/user-management/user',
      element: pages.User
    }
  ]
},{
  serialNumber: 2,
  path: '/user-management/role',
  icon: <IconRole/>,
  menu: 'management-user',
  elements: [
    {
      path: '/user-management/role',
      element: pages.Role
    }
  ]
},{
  serialNumber: 3,
  path: '/user-management/permission',
  icon: <IconPermission/>,
  menu: 'management-user',
  elements: [
    {
      path: '/user-management/permission',
      element: pages.Permission
    }
  ]
},{
  serialNumber: 4,
  path: '/user-management/audit-log',
  icon: <IconAudit/>,
  menu: 'management-user',
  elements: [
    {
      path: '/user-management/audit-log',
      element: pages.AuditLog
    }
  ]
}];
