import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';

export default function LoadingBars(props) {
  const { className, size } = props;
  const remSize = `${size}rem`;
  const customClass = [styles.root, className].filter(Boolean).join(' ');

  return (
    <div className={customClass} style={{ height: remSize, width: remSize }}>
      <span /><span /><span />
    </div>
  );
}

LoadingBars.defaultProps = {
  className: '',
  size: 2,
};

LoadingBars.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};
