import { FAILED, LOADING, RESET, SET_COUNT_TIME, SUCCESS } from './constants';

const initialState = {
  isLoading: false,
  message: [],
  countDownTime: null,
  code: 0,
  btnCountTime: null,
  qrCountTime: null,
};

export default function reducer(state = initialState, action) {
  const {
    type,
    isLoading,
    message,
    countDownTime,
    code,
    btnCountTime,
    qrCountTime,
  } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        message,
        countDownTime,
        code,
      };
    case SUCCESS:
      return {
        ...state,
        message,
        code,
        btnCountTime: null,
        qrCountTime: null,
      };
    case LOADING:
      return {
        ...state,
        isLoading,
      };
    case SET_COUNT_TIME:
      return {
        ...state,
        btnCountTime,
        qrCountTime,
      };
    case RESET:
      return initialState;

    default:
      return state;
  }
}
