import { useSessionExpired } from './SessionExpiredContext';

let openModalOtp;
let closeModalOtp;

const SessionExpiredHelper = () => {
  const sessionExpired = useSessionExpired();
  openModalOtp = sessionExpired.openModalOtp;
  closeModalOtp = sessionExpired.closeModalOtp;
  return null;
};

export { openModalOtp, closeModalOtp, SessionExpiredHelper };
