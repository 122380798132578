import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css'; // Pastikan path ini benar
import IconSuccess from '../../../icons/IconSuccess';
import IconInfoRevition from '../../../icons/IconInfoRevition';
import IconFailed from '../../../icons/IconFailed';

function IconToastr({ type }) {
  switch (type) {
    case 'success':
      return <IconSuccess className={styles.icon} />;
    case 'info':
      return <IconInfoRevition className={styles.icon} />;
    default:
      return <IconFailed className={styles.icon} />;
  }
}

export default IconToastr;

IconToastr.defaultProps = {
  type: 'success',
};

IconToastr.propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'failed']),
};
