/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconMerchantNoOnboard({ className }){
  return(
    <svg className={className} fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 10C8.53757 10 11 12.4624 11 15.5C11 18.5376 8.53757 21 5.5 21C2.46243 21 0 18.5376 0 15.5C0 12.4624 2.46243 10 5.5 10ZM15.7488 0C16.9915 0 17.9988 1.00736 17.9988 2.25V17.75C17.9988 18.9926 16.9915 20 15.7488 20L10.1896 20.0008C11.3109 18.8328 12 17.2469 12 15.5C12 11.9101 9.08985 9 5.5 9C4.61413 9 3.76965 9.17722 3 9.49813V2.25C3 1.00736 4.00736 0 5.25 0H15.7488ZM5.5 17.88C5.15509 17.88 4.87549 18.1596 4.87549 18.5045C4.87549 18.8494 5.15509 19.129 5.5 19.129C5.84491 19.129 6.12451 18.8494 6.12451 18.5045C6.12451 18.1596 5.84491 17.88 5.5 17.88ZM5.50004 12.0031C5.25458 12.0031 5.05044 12.18 5.0081 12.4132L5.00004 12.5031V16.5006L5.0081 16.5905C5.05044 16.8237 5.25458 17.0006 5.50004 17.0006C5.7455 17.0006 5.94965 16.8237 5.99199 16.5905L6.00004 16.5006V12.5031L5.99199 12.4132C5.94965 12.18 5.7455 12.0031 5.50004 12.0031ZM19.75 13.0019C20.1297 13.0019 20.4435 13.2841 20.4932 13.6502L20.5 13.7519V15.25C20.5 15.6297 20.2178 15.9435 19.8518 15.9932L19.75 16H19V13.0019H19.75ZM19.75 9.00194C20.1297 9.00194 20.4435 9.28409 20.4932 9.65017L20.5 9.75194V11.25C20.5 11.6297 20.2178 11.9435 19.8518 11.9932L19.75 12H19V9.00194H19.75ZM19.75 5.00194C20.1297 5.00194 20.4435 5.28409 20.4932 5.65017L20.5 5.75194V7.25C20.5 7.6297 20.2178 7.94349 19.8518 7.99315L19.75 8H19V5.00194H19.75ZM13.748 3.50058H7.24809C6.86839 3.50058 6.5546 3.78273 6.50493 4.14881L6.49809 4.25058V5.75097C6.49809 6.13067 6.78024 6.44446 7.14632 6.49412L7.24809 6.50097H13.748C14.1277 6.50097 14.4415 6.21882 14.4911 5.85274L14.498 5.75097V4.25058C14.498 3.83637 14.1622 3.50058 13.748 3.50058Z" fill="currentColor"/>
    </svg>

  );
}
IconMerchantNoOnboard.defaultProps = {
  className: ''
};
IconMerchantNoOnboard.propTypes = {
  className: PropTypes.string
};
