import { actionTypes } from './constants';
import { initialValues as filterTable } from '../../components/fragments/SettingMerchantFilter/fields';

const state = {
  data: [],
  loading: false,
  message: '',
  success: false,
  filter: {},
  meta: {},
};

export const initialState = {
  table: {
    ...state,
    data: [],
    filter: { ...filterTable },
    meta: {
      page: 1,
      size: 100,
      totalPage: 1,
      totalData: 0,
      summary: [],
    },
  },
  agen: { ...state, data: [] },
  detail: { ...state, data: {} },
  suspend: state,
  bank: { ...state, data: { isChecked: undefined } },
  edit: { ...state, form: null },
  resend: state,
  identity: { ...state, data: {} },
  search: { ...state, data: null },
  transactionManual: {
    ...state,
    data: [],
    meta: {
      page: 1,
      size: 100,
      totalPage: 1,
      totalData: 0,
      summary: [],
    },
  },
  sync: {
    data: {
      reasons: [],
    },
    loading: false,
    message: '',
    success: false,
  },
  deactivate: state,
  agenHistory: state
};

export default function reducer(state = initialState, action) {
  const {
    type,
    data,
    loading,
    message = '',
    success = true,
    meta,
    // initial = true,
    filter,
    key,
    form,
    error,
    code
  } = action;

  switch (type) {
    case actionTypes.FAILED:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: data || initialState[key].data,
          error: error,
          loading: false,
          message,
          success: false,
          code,
        },
      };
    case actionTypes.LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          success: false,
          loading,
          message: '',
        },
      };
    case actionTypes.SUCCESS:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: data,
          meta,
          loading: false,
          error: {},
          message,
          success,
          code,
        },
      };
    case actionTypes.FILTER:
      return {
        ...state,
        [key]: {
          ...state[key],
          filter,
        },
      };
    case actionTypes.MESSAGE:
      return {
        ...state,
        [key]: {
          ...state[key],
          success: false,
          loading: false,
          message,
        },
      };
    case actionTypes.FORM:
      return {
        ...state,
        [key]: {
          ...state[key],
          form
        },
      };
    case actionTypes.RESET:
      return{
        ...initialState
      };
    default:
      return state;
  }
}
