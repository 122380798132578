import { CLEAR_MESSAGE, FAILED, LOADING, SET_DATA, SUCCESS } from './constants';

export const initialState = {
  search: {
    data: null,
    loading: false,
    success: true,
    message: '',
  },
  detail: {
    data: null,
    loading: false,
    success: false,
    message: '',
  },
  edit: {
    data: null,
    loading: false,
    success: true,
    message: '',
  },
  suspend: {
    data: null,
    loading: false,
    success: true,
    message: '',
  },
  add: {
    data: null,
    loading: false,
    success: true,
    message: '',
  },
  loading: false,
  error: {},
  message: '',
  success: true,
};

export default function reducer(state = initialState, action) {
  const { type, data, key, loading, message, success, actionType } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [key]: {
          loading:
          false,
          success: false,
          message,
          data: null
        }
      };
    case LOADING:
      return {
        ...state,
        [key]: {
          loading,
          success: false,
          message: '',
          data: null
        }
      };
    case SUCCESS:
      return {
        ...state,
        [key]: {
          data,
          loading: false,
          success,
          message: '',
        }
      };
    case SET_DATA:
      return {
        ...state,
        [key]:{
          data,
          loading: false,
          success: true,
          message: message,
          actionType
        }
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        [key]: initialState[key]
      };
    default:
      return state;
  }
}
