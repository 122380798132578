import { SUCCESS, FAILED, LOADING } from './constants';

export const initialState = {
  changePassword: {
    data: null,
    message: '',
    code: 0,
    success: false,
    isLoading: false,
    isExpired: false,
  },
  countdownt:{
    data: null,
    message: '',
    code: 0,
    success: false,
    isLoading: false,
  }
};

export default function reducer(state = initialState, action) {
  const { type, message, code, data, isLoading, key, isExpired } = action;
  switch (type) {
    case FAILED:
      return {
        ...state,
        [key]: {
          data,
          message,
          code,
          success: false,
          isLoading: false,
          isExpired
        }
      };
    case LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading,
        },
      };
    case SUCCESS:
      return {
        ...state,
        [key]: {
          ...data,
          isLoading: false,
          isExpired,
          code
        }
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
}
