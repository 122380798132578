import { FAILED, LOADING, SUCCESS } from './constants';

const initialState = {
  isLoading: true,
  message: '',
  data: {},
  errorCode: 0,
};

export default function reducer(state = initialState, action) {
  const { type, isLoading, message, errorCode, data } = action;

  switch (type) {
    case SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case FAILED:
      return {
        ...state,
        message,
        errorCode,
      };

    case LOADING:
      return {
        ...state,
        isLoading,
      };
    default:
      return state;
  }
}
